import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import mobileImage from "../img/liveShare.png";
const LiveShare = () => {
  return (
    <div className="palning-main-area live-share-area" id="liveShare">
      <Container>
        <div className="palning-inner-area-wrap live-share-inner">
          <Row>
            <Col lg={6} className="my-auto">
              <div className="feature-inner-single-item LiveShare">
                <h4>Interactive Dashboard</h4>
                <p>Make lightning fast decisions with live insights</p>
                <button className=" btn feature-left-btn  hero btn--swap ">
                  <div>
                    <span className="hero-button-area-left-1">
                      <a
                        href="https://maacsolutions.com/contact"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        Request for Demo
                      </a>
                    </span>
                    <span
                      aria-hidden="true"
                      className="hero-button-area-left-2"
                    >
                      <a
                        href="https://maacsolutions.com/contact"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        Request for Demo
                      </a>
                    </span>
                  </div>
                </button>
              </div>
            </Col>
            <Col lg={6} className="my-auto">
              <div className="feature-inner-single-item image-feature-mobile image-bouns-animation">
                <img src={mobileImage} alt="" />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default LiveShare;
