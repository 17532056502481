import { Col, Container, Row } from "react-bootstrap";
import heroLogo from "../../../Common/img/SalesX.svg";
import heroImage from "../img/heroRight.png";
import heroImageBrandIcon from "../img/heroRightBrand.svg";
const Hero = () => {
  return (
    <div className="hero-container-main-wrap" id="hero">
      <div className="fieldx-hero-inner-wrap">
        <Container>
          <div className="hero-inner-wrap">
            <div
              style={{ backgroundImage: `url(${heroImageBrandIcon})` }}
              className="hero-brand-icon-right"
            />
            <div className="hero-top-content-wrap-fieldx">
              <Row>
                <Col lg={12} className="my-auto">
                  <div className="hero-item-left-content">
                    <h3>
                      <img src={heroLogo} alt="" />
                    </h3>
                    <p>
                      Axs is the corridor of all access to issues we might need
                      to resolve in our professional and personal setup. All we
                      need is task identification and the brevity of the tasks
                      to be engaged in such a manner that issues get noticed,
                      tracked, escalated to the right audience, and gets
                      resolved.
                    </p>

                    <div className="hero-main-area-button">
                      <button className=" btn feature-left-btn  hero btn--swap request-demon-btn">
                        <div>
                          <span className="hero-button-area-left-1">
                            <a
                              href="https://maacsolutions.com/contact"
                              target="_blank"
                              rel="noreferrer"
                            >
                              {" "}
                              Request for Demo
                            </a>
                          </span>
                          <span
                            aria-hidden="true"
                            className="hero-button-area-left-2"
                          >
                            <a
                              href="https://maacsolutions.com/contact"
                              target="_blank"
                              rel="noreferrer"
                            >
                              {" "}
                              Request for Demo
                            </a>
                          </span>
                        </div>
                      </button>{" "}
                      <button className=" btn feature-left-btn  hero btn--swap download-btn">
                        <div>
                          <span className="hero-button-area-left-1">
                            <a
                              href="https://play.google.com/store/apps/details?id=com.axs.axs"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M21.3995 12.95L18.6195 14.34C18.4295 14.44 18.1995 14.4 18.0395 14.25L16.1695 12.38C15.9695 12.18 15.9695 11.87 16.1695 11.67L18.0695 9.77002C18.2195 9.62002 18.4595 9.58002 18.6495 9.68002L21.3995 11.06C22.1895 11.44 22.1895 12.56 21.3995 12.95Z"
                                  fill="#fd7e14"
                                />
                                <path
                                  d="M16.3009 8.48948C16.6109 8.63948 16.6709 9.04949 16.4309 9.28949L14.7609 10.9595L14.0509 11.6695C13.8509 11.8695 13.5409 11.8695 13.3409 11.6695L6.20095 4.52948C5.80095 4.12948 6.27095 3.4795 6.78095 3.7295L16.3009 8.48948Z"
                                  fill="#fd7e14"
                                />
                                <path
                                  d="M2 20.1491V3.47911C2 3.03911 2.53 2.80909 2.85 3.11909L11.62 11.6291C11.83 11.8291 11.82 12.1591 11.61 12.3591L2.84 20.5191C2.52 20.8191 2 20.5891 2 20.1491Z"
                                  fill="#fd7e14"
                                />
                                <path
                                  d="M16.2686 15.5207L6.87861 20.2207C6.36861 20.4707 5.89861 19.8207 6.29861 19.4207L13.3486 12.3707C13.5486 12.1707 13.8586 12.1707 14.0586 12.3707L16.4086 14.7207C16.6386 14.9607 16.5686 15.3707 16.2686 15.5207Z"
                                  fill="#fd7e14"
                                />
                              </svg>
                              Download
                            </a>
                          </span>
                          <span
                            aria-hidden="true"
                            className="hero-button-area-left-2"
                          >
                            <a
                              href="https://play.google.com/store/apps/details?id=com.axs.axs"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M21.3995 12.95L18.6195 14.34C18.4295 14.44 18.1995 14.4 18.0395 14.25L16.1695 12.38C15.9695 12.18 15.9695 11.87 16.1695 11.67L18.0695 9.77002C18.2195 9.62002 18.4595 9.58002 18.6495 9.68002L21.3995 11.06C22.1895 11.44 22.1895 12.56 21.3995 12.95Z"
                                  fill="#fd7e14"
                                />
                                <path
                                  d="M16.3009 8.48948C16.6109 8.63948 16.6709 9.04949 16.4309 9.28949L14.7609 10.9595L14.0509 11.6695C13.8509 11.8695 13.5409 11.8695 13.3409 11.6695L6.20095 4.52948C5.80095 4.12948 6.27095 3.4795 6.78095 3.7295L16.3009 8.48948Z"
                                  fill="#fd7e14"
                                />
                                <path
                                  d="M2 20.1491V3.47911C2 3.03911 2.53 2.80909 2.85 3.11909L11.62 11.6291C11.83 11.8291 11.82 12.1591 11.61 12.3591L2.84 20.5191C2.52 20.8191 2 20.5891 2 20.1491Z"
                                  fill="#fd7e14"
                                />
                                <path
                                  d="M16.2686 15.5207L6.87861 20.2207C6.36861 20.4707 5.89861 19.8207 6.29861 19.4207L13.3486 12.3707C13.5486 12.1707 13.8586 12.1707 14.0586 12.3707L16.4086 14.7207C16.6386 14.9607 16.5686 15.3707 16.2686 15.5207Z"
                                  fill="#fd7e14"
                                />
                              </svg>
                              Download
                            </a>
                          </span>
                        </div>
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <Row>
              <Col lg={12} className="my-auto">
                <div className="hero-footer-image-wrap-fieldx-">
                  <Row>
                    <Col lg={8} className="my-auto">
                      <div className="hero-item-right-content image-bouns-animation">
                        <img src={heroImage} alt="" />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Hero;
