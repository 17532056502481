import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import Header from "./Common/Header";
import AxsLanding from "./Pages/AxsHome/AxsLanding";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Header />
          <AxsLanding />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
