import { Col, Container, Row } from "react-bootstrap";
import serviceBg from "../img/bgshape.png";
const Service = () => {
  return (
    <div className="service-main-area">
      <Container>
        <div
          className="service-inner-area-wrap"
          style={{ backgroundImage: `url(${serviceBg})` }}
        >
          <div className="service-area-top-content">
            <h2>We believe in the power of data</h2>
            <p>
              Flex is the only business platform that lets you run your business
              on one platform, <br /> seamlessly across all digital channels.
            </p>
          </div>
          <Row>
            <Col lg={3}>
              <div className="service-single-data">
                <h1>235.000</h1>
                <p>Projects completed</p>
              </div>
            </Col>{" "}
            <Col lg={3}>
              <div className="service-single-data">
                <h1>$10m</h1>
                <p>APR</p>
              </div>
            </Col>{" "}
            <Col lg={3}>
              <div className="service-single-data">
                <h1>+50.000</h1>
                <p>Hours Saved Annually</p>
              </div>
            </Col>{" "}
            <Col lg={3}>
              <div className="service-single-data">
                <h1>3.500</h1>
                <p>Unique Users</p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Service;
