import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import MainFooter from "../../footer/MainFooter";
import "./AnimateStyle.css";
import CallToAction1 from "./CallToAction/CallToAction1";
import Feature from "./Feature/Feature";
import Hero from "./Hero/Hero";
import Industries from "./Industries/Industries";
import LiveShare from "./LiveInsights/LiveShare";
import Planing from "./Planing/Planing";
import "./salesx.scss";
import Service from "./Service/Service";
const AxsLanding = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <main>
        <Hero />
        <Service />
        <Feature />
        <LiveShare />
        <Planing />

        <Industries />
        <CallToAction1 />
        <MainFooter />
      </main>
    </div>
  );
};

export default AxsLanding;
