import { Col, Container, Row } from "react-bootstrap";
import mobileImage from "../img/featureMobile.png";
import icon1 from "../img/icon/1.svg";
import icon2 from "../img/icon/2.svg";
import icon3 from "../img/icon/3.svg";
import icon4 from "../img/icon/4.svg";
const Feature = () => {
  return (
    <div className="feature-main-area" id="feature">
      <Container>
        <div className="feature-inner-area-wrap">
          <Row>
            <Col lg={5} className="my-auto">
              <div className="feature-inner-single-item image-feature-mobile image-bouns-animation feature-middle-app-pic">
                <img src={mobileImage} alt="" />
              </div>
            </Col>
            <Col lg={7} className="my-auto">
              <Row>
                <Col lg={6} className="my-auto">
                  {" "}
                  <div className="feature-inner-single-item">
                    <span>
                      <img src={icon1} alt="" />
                    </span>
                    <h4>Attandance</h4>
                  </div>{" "}
                </Col>
                <Col lg={6} className="my-auto">
                  {" "}
                  <div className="feature-inner-single-item">
                    <span>
                      <img src={icon2} alt="" />
                    </span>
                    <h4>Issue Create</h4>
                  </div>
                </Col>
                <Col lg={6} className="my-auto">
                  {" "}
                  <div className="feature-inner-single-item">
                    <span>
                      <img src={icon3} alt="" />
                    </span>
                    <h4>Issue History</h4>
                  </div>{" "}
                </Col>
                <Col lg={6} className="my-auto">
                  <div className="feature-inner-single-item">
                    <span>
                      <img src={icon4} alt="" />
                    </span>
                    <h4>Assign School</h4>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Feature;
